import React, { useContext, useState, useMemo, useEffect } from "react";
import PropTypes from "prop-types";

import { useSelector } from "react-redux";
import { BigNumber } from "bignumber.js";
import { debounce } from "lodash";

import Web3Utils from "web3-utils";
import { useWeb3React } from "@web3-react/core";

import { EventContext } from "../../../contexts/EventContext";
import * as S from "../../../store/selectors";

import {
  Grid,
  Row,
  Col,
  Space,
  Typography,
  Button,
  notification,
  message,
} from "antd";
import { FightIcon, OraculaIcon, UsersIcon, CoinsIcon } from "../../Icons";
import FormatInput from "../../FormatInput";
import AnimatedNumbers from "../../AnimatedNumbers";

// import "./style.less";

const { useBreakpoint } = Grid;
const { Text, Link } = Typography;

const EventBetsForm = ({ optionId }) => {
  const {
    eventContract,
    tokenContract,
    event,
    creator,
    bets,
    onFetchContractInfo,
    // coefficients,
    // outcomeId,
    // isOutcomeSet,
    // isEventBetsEnd,
    // isEventStart,
    // isEventEnd,
    // isUserInEvent,
    // userOutcomeId,
  } = useContext(EventContext);
  const { account } = useWeb3React();
  const breakpoint = useBreakpoint();

  const isAuthenticated = useSelector(S.profile.selectIsAuthenticated);
  const oraculaBalance = useSelector(S.profile.selectOraculaBalance);

  const [allowance, setAllowance] = useState("0");
  const [isApproved, setIsApproved] = useState(false);
  const [isApproveLoading, setIsApproveLoading] = useState(false);
  const [isApproveChecking, setIsApproveChecking] = useState(false);

  const [futureCoefficient, setFutureCoefficient] = useState("0");
  const [amount, setAmount] = useState("");
  const [isBetLoading, setIsBetLoading] = useState(false);

  const totalBetsCount = useMemo(
    () =>
      bets.filter(
        ({ bets }) => bets.filter((bet) => bet.outcomeId === optionId).length
      ).length,
    [bets, optionId]
  );

  const totalBetsAmount = useMemo(
    () =>
      bets
        .filter(
          ({ bets }) => bets.filter((bet) => bet.outcomeId === optionId).length
        )
        .reduce(
          (acc, item) =>
            new BigNumber(acc)
              .plus(
                item.bets.reduce(
                  (acc2, item2) =>
                    new BigNumber(acc2).plus(item2.amount).toFixed(),
                  "0"
                )
              )
              .toFixed(),
          "0"
        ),
    [bets, optionId]
  );

  const onApprove = async () => {
    if (!isAuthenticated) {
      return message.warning(
        "You must be logged in before approve ORACULA token!"
      );
    }

    if (creator.toLocaleLowerCase() === account.toLocaleLowerCase()) {
      return message.warning(
        "You cannot take part in this event because you are the author!"
      );
    }

    tokenContract.methods
      .approve(
        eventContract.options.address,
        Web3Utils.toWei(Number.MAX_SAFE_INTEGER.toString())
      )
      .send({ from: account })
      .on("error", (error) => {
        setIsApproveLoading(false);

        notification.error({
          message: "Transaction error",
          description: error.message,
        });
      })
      .on("transactionHash", (tx) => {
        setIsApproveLoading(true);

        notification.info({
          message: "Transaction send",
          description: (
            <>
              Follow the transaction on{" "}
              <Link
                href={`${process.env.REACT_APP_EXPLORER}tx/${tx}`}
                target="_blank"
                rel="noreferrer"
              >
                {process.env.REACT_APP_EXPLORER_NAME}
              </Link>
            </>
          ),
        });
      })
      .once("confirmation", (_confirmationNumber, receipt) => {
        setIsApproveLoading(false);

        const { status, transactionHash: tx } = receipt;

        if (status) {
          setIsApproved(true);

          notification.success({
            message: "Transaction completed",
            description: (
              <>
                More info at{" "}
                <Link
                  href={`${process.env.REACT_APP_EXPLORER}tx/${tx}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {process.env.REACT_APP_EXPLORER_NAME}
                </Link>
              </>
            ),
          });
        }
      });
  };

  useEffect(() => {
    if (tokenContract && eventContract && isAuthenticated) {
      (async () => {
        setIsApproveChecking(true);
        const amount = await tokenContract.methods
          .allowance(account, eventContract.options.address)
          .call();

        setAllowance(amount);
        setIsApproved(amount > 0);
        setIsApproveChecking(false);
      })();
    } else {
      setIsApproved(false);
    }
  }, [tokenContract, eventContract, isAuthenticated, account]);

  const onChangeAmount = debounce(async ({ value }) => {
    setAmount(value);

    if (value && value >= 0) {
      const coef = await eventContract.methods
        .getFutureCoefficient(
          event.idOnContract,
          optionId,
          Web3Utils.toWei(value)
        )
        .call();

      setFutureCoefficient(coef);
    } else {
      setFutureCoefficient("0");
    }
  }, 300);

  const onBet = async () => {
    if (!isAuthenticated) {
      return message.warning("You must be logged in before place a bet!");
    }

    if (creator.toLocaleLowerCase() === account.toLocaleLowerCase()) {
      return message.warning(
        "You cannot take part in this event because you are the author!"
      );
    }

    const { startTime, endTime } = await eventContract.methods
      .eventInfo(event.idOnContract)
      .call();

    const isBettingAvailable =
      startTime < Math.floor(Date.now() / 1000) &&
      Math.floor(Date.now() / 1000) < endTime;

    if (!isBettingAvailable) {
      return message.warning("Time to bet is over!");
    }

    const { eventIds, outcomeIds } = await eventContract.methods
      .getAllUserBets(account)
      .call();

    const isBetExistAndNotSameOutcome = eventIds.find(
      (item, index) =>
        item === event.idOnContract && outcomeIds[index] !== optionId
    );

    if (isBetExistAndNotSameOutcome) {
      return message.warning("You can't bet on another outcome!");
    }

    if (amount <= 0) {
      return message.warning("Enter bet amount!");
    }

    // const isAllowanceLessThanBalance = new BigNumber(allowance).isLessThan(
    //   oraculaBalance
    // );

    // if (isAllowanceLessThanBalance) {
    //   return message.warning(
    //     <Text>
    //       <Space direction={breakpoint.lg ? "horizontal" : "vertical"}>
    //         Not enough allowance.{" "}
    //         <Button type="primary" size="small" onClick={onApprove}>
    //           Approve
    //         </Button>
    //       </Space>
    //     </Text>
    //   );
    // }

    const isNotEnoughTokens = new BigNumber(oraculaBalance).isLessThan(
      Web3Utils.toWei(amount)
    );

    if (isNotEnoughTokens) {
      return message.warning(
        <Text>
          <Space direction={breakpoint.lg ? "horizontal" : "vertical"}>
            Not enough tokens to bet.{" "}
            <Link
              href={`https://pancakeswap.finance/swap?outputCurrency=${tokenContract.options.address}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button type="primary" size="small">
                Buy $ORC
              </Button>
            </Link>
          </Space>
        </Text>
      );
    }

    eventContract.methods
      .placeBet(event.idOnContract, Web3Utils.toWei(amount), optionId)
      .send({ from: account })
      .on("error", (error) => {
        notification.error({
          message: "Transaction error",
          description: error.message,
        });
      })
      .on("transactionHash", (tx) => {
        setIsBetLoading(true);

        notification.info({
          message: "Transaction send",
          description: (
            <>
              Follow the transaction on{" "}
              <Link
                href={`${process.env.REACT_APP_EXPLORER}tx/${tx}`}
                target="_blank"
                rel="noreferrer"
              >
                {process.env.REACT_APP_EXPLORER_NAME}
              </Link>
            </>
          ),
        });
      })
      .once("confirmation", (_confirmationNumber, receipt) => {
        setIsBetLoading(false);

        const { status, transactionHash: tx } = receipt;

        if (status) {
          onChangeAmount({ value: 0 });

          notification.success({
            message: "Transaction completed",
            description: (
              <>
                More info at{" "}
                <Link
                  href={`${process.env.REACT_APP_EXPLORER}tx/${tx}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {process.env.REACT_APP_EXPLORER_NAME}
                </Link>
              </>
            ),
          });
        }

        onFetchContractInfo();
      });
  };

  return (
    <div className="event-bets-form">
      <Row gutter={[{ sm: 20, xs: 20 }, 20]}>
        <Col sm={5} span={12}>
          <Row gutter={[0, { lg: 20, sm: 10, xs: 10 }]}>
            <Col span={12}>
              <Text>
                <Space>
                  <FightIcon style={{ fontSize: "24px" }} />
                  <Text>Place a bet</Text>
                </Space>
              </Text>
            </Col>

            <Col span={12}>
              <Row gutter={[0, 5]}>
                <Col span={12}>
                  <Row align="middle" gutter={20}>
                    <Col span={7}>
                      <Text>Available $ORC:</Text>
                    </Col>

                    <Col
                      span={5}
                      style={{ display: "flex", justifyContent: "end" }}
                    >
                      <AnimatedNumbers
                        value={Web3Utils.fromWei(oraculaBalance)}
                        style={{
                          fontSize: 16,
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          onChangeAmount({
                            value: Web3Utils.fromWei(oraculaBalance),
                          })
                        }
                      />
                    </Col>
                  </Row>
                </Col>

                <Col span={12}>
                  <FormatInput
                    value={amount}
                    decimalScale={2}
                    onChange={onChangeAmount}
                  />
                </Col>

                {/* <Col span={12}>
                  <Text type="secondary">or choose popular amount:</Text>
                </Col> */}
              </Row>
            </Col>

            <Col span={12}>
              {isApproved ? (
                <Button
                  type="primary"
                  block
                  loading={isBetLoading}
                  onClick={onBet}
                >
                  Place a bet
                </Button>
              ) : (
                <Button
                  type="primary"
                  block
                  loading={isApproveLoading || isApproveChecking}
                  onClick={onApprove}
                >
                  Approve
                </Button>
              )}
            </Col>
          </Row>
        </Col>

        <Col sm={7} span={12}>
          <Row
            gutter={[
              { sm: 40, xs: 10 },
              { xl: 40, lg: 20, sm: 10, xs: 10 },
            ]}
          >
            <Col xl={6} sm={12} span={6}>
              <Space align="start">
                <OraculaIcon style={{ marginTop: "3px", fontSize: "19px" }} />
                <Space direction="vertical" size={0}>
                  <Text>Potential Winning:</Text>
                  <AnimatedNumbers
                    value={new BigNumber(
                      amount * Web3Utils.fromWei(futureCoefficient)
                    ).toFixed()}
                    style={{
                      fontSize: breakpoint.lg ? 32 : 24,
                      background:
                        "linear-gradient(142.76deg, #FDC830 13.89%, #F37335 85.84%)",
                      WebkitBackgroundClip: "text",
                      WebkitTextFillColor: "transparent",
                      backgroundClip: "text",
                      textFillColor: "transparent",
                    }}
                  />
                </Space>
              </Space>
            </Col>

            <Col xl={6} sm={12} span={6}>
              <Space align="start">
                {/* <OraculaIcon style={{ marginTop: "3px", fontSize: "19px" }} /> */}
                <Space direction="vertical" size={0}>
                  <Text>Ratio:</Text>
                  <AnimatedNumbers
                    value={new BigNumber(
                      Web3Utils.fromWei(futureCoefficient)
                    ).toFixed(2)}
                    style={{
                      fontSize: breakpoint.sm ? 20 : 16,
                    }}
                  />
                </Space>
              </Space>
            </Col>

            <Col xl={6} sm={12} span={6}>
              <Space align="start">
                <UsersIcon style={{ marginTop: "3px", fontSize: "20px" }} />
                <Space direction="vertical" size={0}>
                  <Text type="secondary">Participants:</Text>
                  <AnimatedNumbers
                    value={totalBetsCount} // TODO
                    decimals={0}
                    style={{
                      fontSize: breakpoint.sm ? 20 : 16,
                    }}
                  />
                </Space>
              </Space>
            </Col>

            <Col xl={6} sm={12} span={6}>
              <Space align="start">
                <CoinsIcon style={{ marginTop: "3px", fontSize: "20px" }} />
                <Space direction="vertical" size={0}>
                  <Text type="secondary">Total bets:</Text>
                  <AnimatedNumbers
                    value={Web3Utils.fromWei(totalBetsAmount)}
                    style={{ fontSize: breakpoint.sm ? 20 : 16 }}
                  />
                </Space>
              </Space>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

EventBetsForm.propTypes = {
  optionId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default EventBetsForm;
