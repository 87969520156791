import axios from "../js/utils/axios";

const event = {
  getCategories: async () => {
    const res = await axios.get("/event/categories/");
    const { result } = res.data;

    return {
      result: result.map(({ id, title, image, gradient }) => ({
        id,
        title,
        icon: image,
        gradient,
      })),
    };
  },

  getTags: async () => {
    const res = await axios.get("/event/tags/");
    const { result } = res.data;

    return {
      result: result.map(({ id, title, is_empty }) => ({
        id,
        title,
        isEmpty: is_empty,
      })),
    };
  },

  getTopics: async ({ category = "" } = {}) => {
    const res = await axios.get(`/event/topic/?category=${category}`);
    const { result } = res.data;

    return {
      result: result.map(({ id, category_id, title, image }) => ({
        id,
        categoryId: category_id,
        title,
        image,
      })),
    };
  },

  getTopic: async ({ id }) => {
    try {
      const res = await axios.get(`/event/topic/${id}/`);
      const {
        result: { id: topicId, category_id, title, image },
      } = res.data;

      return {
        result: {
          id: topicId,
          categoryId: category_id,
          title,
          image,
        },
      };
    } catch ({ response }) {
      const {
        status,
        data: { result },
      } = response;

      return {
        error: {
          code: status,
          message: result,
        },
      };
    }
  },

  getEvents: async ({
    page = 1,
    pageSize = "999",
    sort = "",
    category = "",
    tag = "",
    topic = "",
    events = "",
    search = "",
    gte = "",
    isResultSet = "",
    isRefund = "",
  } = {}) => {
    const params = `?page=${page}&page_size=${pageSize}&order=${sort}&category=${category}&tags=${tag}&topic=${topic}&id_on_contract__in=${events}&search=${search}&is_result_set=${isResultSet}&is_refund=${isRefund}&end_date__gte=${gte}`;
    const res = await axios.get(`/event/${params}`);
    const {
      result: { events_amount, results },
    } = res.data;

    return {
      result: {
        count: events_amount,
        list: results.map(
          ({
            link,
            id_on_contract,
            contract_id,
            token_id,
            category_id,
            topic_id,
            title,
            description,
            card_image,
            badges,
            tags,
            options,
            proof_links,
            start_date,
            end_date,
            is_public,
            is_active,
          }) => ({
            id: link,
            idOnContract:
              id_on_contract !== null ? String(id_on_contract) : null,
            contractId: contract_id,
            tokenId: token_id,
            categoryId: category_id,
            topicId: topic_id,
            title,
            description,
            cardImage: card_image,
            badges,
            tags,
            options: options.map(({ id, title }) => ({
              id: String(id),
              title,
            })),
            sources: proof_links,
            startDate: start_date,
            endDate: end_date,
            isPublic: is_public,
            isActive: is_active,
          })
        ),
      },
    };
  },

  getTrendingEvents: async () => {
    const res = await axios.get("/event/trending_events/");
    const { result } = res.data;

    return {
      result: {
        list: result.map(
          ({
            link,
            id_on_contract,
            contract_id,
            token_id,
            category_id,
            topic_id,
            title,
            description,
            card_image,
            badges,
            tags,
            options,
            proof_links,
            start_date,
            end_date,
            is_public,
            is_active,
          }) => ({
            id: link,
            idOnContract: String(id_on_contract),
            contractId: contract_id,
            tokenId: token_id,
            categoryId: category_id,
            topicId: topic_id,
            title,
            description,
            cardImage: card_image,
            badges,
            tags,
            options: options.map(({ id, title }) => ({
              id: String(id),
              title,
            })),
            sources: proof_links,
            startDate: start_date,
            endDate: end_date,
            isPublic: is_public,
            isActive: is_active,
          })
        ),
      },
    };
  },

  getEvent: async ({ id }) => {
    try {
      const res = await axios.get(`/event/${id}/`);
      const {
        result: {
          link,
          id_on_contract,
          contract_id,
          token_id,
          category_id,
          topic_id,
          title,
          description,
          card_image,
          banner_images,
          badges,
          tags,
          options,
          proof_links,
          start_date,
          end_date,
          is_public,
          is_active,
        },
      } = res.data;

      return {
        result: {
          id: link,
          idOnContract: id_on_contract !== null ? String(id_on_contract) : null,
          contractId: contract_id,
          tokenId: token_id,
          categoryId: category_id,
          topicId: topic_id,
          title,
          description,
          cardImage: card_image,
          coverImages: banner_images.map(({ link, file }) => ({
            id: link,
            image: file,
          })),
          badges,
          tags,
          options: options.map(({ id, title }) => ({
            id: String(id),
            title,
          })),
          sources: proof_links,
          startDate: start_date,
          endDate: end_date,
          isPublic: is_public,
          isActive: is_active,
        },
      };
    } catch ({ response }) {
      const {
        status,
        data: { result },
      } = response;

      return {
        error: {
          code: status,
          message: result,
        },
      };
    }
  },

  getEventParticipantsByAddresses: async ({ addresses }) => {
    const res = await axios.post("/user/participants/", addresses);
    const { result } = res.data;

    return {
      result: result.map(({ address, nickname, avatar_img }) => ({
        address,
        nickname,
        avatar: avatar_img,
      })),
    };
  },

  getEventComments: async ({ id }) => {
    const transformAuthor = ({ address, nickname, avatar_img }) => ({
      address,
      nickname,
      avatar: avatar_img,
    });
    const transformComments = ({
      link,
      author,
      content,
      is_deleted,
      is_modified,
      likes_amount,
      dislikes_amount,
      child_comments,
    }) => ({
      id: link,
      author: transformAuthor({ ...author }),
      content,
      isDeleted: is_deleted,
      isModified: is_modified,
      likes: likes_amount,
      dislikes: dislikes_amount,
      childComments: child_comments.map(transformComments),
    });

    try {
      const res = await axios.get(`/event/${id}/comments/`);
      const {
        result: { comments_amount, results },
      } = res.data;

      return {
        result: {
          count: comments_amount,
          list: results.map(transformComments),
        },
      };
    } catch ({ response }) {
      const {
        status,
        data: { result },
      } = response;

      return {
        error: {
          code: status,
          message: result,
        },
      };
    }
  },

  createEvent: async ({
    categoryId,
    tags,
    title,
    description,
    startDate,
    endDate,
    isPublic,
    coverImages,
    options,
    links,
  }) => {
    try {
      const formData = new FormData();

      formData.append("category_id", categoryId);
      formData.append("title", title);
      formData.append("description", description);
      formData.append("start_date", startDate);
      formData.append("end_date", endDate);
      formData.append("is_public", isPublic);

      coverImages.forEach((item, index) =>
        formData.append(
          `banner_images[${index}]${item.file ? "file" : "link"}`,
          item.file ? item.file : item.preview
        )
      );
      tags.forEach((item, index) =>
        formData.append(`tags[${index}]title`, item)
      );
      options.forEach((item, index) =>
        formData.append(`options[${index}]title`, item)
      );
      links
        .filter((link) => link)
        .forEach((item, index) =>
          formData.append(`proof_links[${index}]link`, item)
        );

      await axios.post("/user/event/", formData);

      return { error: false };
    } catch ({ response }) {
      const {
        status,
        data: { result },
      } = response;

      return {
        error: {
          code: status,
          message: result,
        },
      };
    }
  },
};

export default event;
